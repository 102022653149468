import Avensis from '../images/avensis-t.png'
import PradoVx from '../images/prado-t.png'
import ToyotaLogo from '../images/toyota-logo.png'
import CameroonFlag from '../images/cameroon-flag.png'
import LogoWhite from '/static/img/LogoWhite.png'
import bgImage from '../images/bg.svg'

import IMG3 from '../images/apartment/IMG_3.jpg'
import IMG4 from '../images/apartment/IMG_4.jpeg'
import IMG5 from '../images/apartment/IMG_5_5.jpg'
import IMG6 from '../images/apartment/IMG_6.jpg'
import IMG7 from '../images/apartment/IMG_7.jpg'
import IMG8 from '../images/apartment/IMG_8.jpg'
import IMG10 from '../images/apartment/IMG_10.jpg'
import IMG11 from '../images/apartment/IMG_11.jpg'
import IMG12 from '../images/apartment/IMG_12.jpg'
import IMG13 from '../images/apartment/IMG_13.jpeg'
import IMG14 from '../images/apartment/IMG_14.jpg'


import LeftArrow from '../images/left-arrow.svg'
import RightArrow from '../images/right-arrow.svg'

const apartmentImages = [ IMG3, IMG4, IMG5, IMG6, IMG7, IMG8, IMG10, IMG11, IMG12, IMG13, IMG14]

export {
    Avensis,
    PradoVx,
    ToyotaLogo,
    CameroonFlag,
    apartmentImages,
    LeftArrow,
    RightArrow,
    LogoWhite,
    bgImage,
}