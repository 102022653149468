import { graphql } from "gatsby";
import React from "react";
import DashboardLayout from "../components/dashboardLayout/index";
import {
  Typography,
  Paper,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DateRangeIcon from "@mui/icons-material/DateRange";

import { styled } from "@mui/material/styles";
import { Avensis, PradoVx } from "../images";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Dashboard = () => {
  const bookingHistory = [
    {
      carName: "Toyota",
      totalDays: 5,
      startDate: "2021-12-30",
      endDate: "2022-01-10",
      photo: PradoVx,
      price: "65000",
    },
    {
      carName: "Toyota",
      totalDays: 5,
      startDate: "2021-12-30",
      endDate: "2022-01-14",
      photo: Avensis,
      price: "35000",
    },
  ];
  const oneDay = 24 * 60 * 60 * 1000;
  return (
    <DashboardLayout>
      <Box component="div" sx={{ padding: "24px 24px 0px 24px" }}>
        <Typography variant="h4">Your orders</Typography>
      </Box>
      <Box
        component="div"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          padding: "24px 24px 0px 24px",
          gap: 2,
          margin: "0 auto",
        }}
      >
        <Box
          p={2}
          position="relative"
          sx={{
            flexBasis: 345,
            height: 245,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "3px dotted #6b6b6b99",
            boxSizing: "border-box",
          }}
        >
          <AddIcon sx={{ fontSize: 100 }} />
          <Typography position="absolute" bottom={60}>
            Place your orders
          </Typography>
        </Box>
        {bookingHistory.map((bh, idx) => {
          return (
            <Card sx={{ flexBasis: 345 }} key={idx}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={bh.photo}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>{bh.carName}</Typography>
                    <Typography sx={{ display: "flex" }}>
                      <DateRangeIcon sx={{ fontSize: "10" }} />
                      <span>
                        {(new Date(bh.endDate).getTime() -
                          new Date(bh.startDate).getTime()) /
                          oneDay}
                        jour(s)
                      </span>
                    </Typography>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    XAF {bh.price} / jours
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    PRIX TOTAL: XAF
                    {parseInt(bh.price) *
                      ((new Date(bh.endDate).getTime() -
                        new Date(bh.startDate).getTime()) /
                        oneDay)}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
    </DashboardLayout>
  );
};
export default Dashboard;
